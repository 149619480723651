import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const AboutHistory = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  imageFill,
  invertMobile,
  invertDesktop,
  alignTop,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Conheça nossa história',
    paragraph: 'Descubra como tudo começou e como ajudamos as empresas a serem mais eficientes'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                O início da nossa história
                  </div>
                <h3 className="mt-0 mb-12">
                  Quando tudo começou
                  </h3>
                <p className="m-0">
                  Criada em 2022 com a visão de transformar a forma como as empresas lidam com seus desafios diários. Com a nossa equipe de especialistas em tecnologia, desenvolvemos aplicações inovadoras que ajudam as empresas a serem mais produtivas, eficientes e competitivas. 
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/bookblock.jpg')}
                  alt="Book block"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Alvos a atingir
                </div>
                <h3 className="mt-0 mb-12">
                  Metas a serem alcançadas
                </h3>
                <p className="m-0">
                Nosso objetivo é ser uma parceira estratégica para nossos clientes, oferecendo soluções tecnológicas personalizadas que atendam às suas necessidades específicas. Estamos comprometidos em estar sempre à frente das tendências do mercado.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/calendar_on_the_table.jpg')}
                  alt="Calendar on the table"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Impulsionados por nossa paixão pela tecnologia
                </div>
                <h3 className="mt-0 mb-12">
                  A parceria estratégica que sua empresa precisa
                </h3>
                <p className="m-0">
                  Criamos a Algorcode com a paixão de mudar a forma como as coisas são feitas e acreditamos que, juntos, podemos alcançar resultados incríveis. Estamos ansiosos para trabalhar com você e ajudar sua empresa a alcançar seus objetivos.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/landscape.jpg')}
                  alt="Beautiful landscape"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

AboutHistory.propTypes = propTypes;
AboutHistory.defaultProps = defaultProps;

export default AboutHistory;