import React from 'react';
import BlogFeatured from '../components/sections/BlogFeatured';
import Cta from '../components/sections/Cta';

import BlankSection from '../components/sections/BlankSection';

const Blog = () => {

  return (
    <>
      <BlogFeatured />
      <Cta split />
      <BlankSection />
    </>
  );
}

export default Blog;