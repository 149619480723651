import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const BlogFeatured = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'blog-featured section center-content-mobile',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'blog-featured-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className="split-wrap blog-featured-content">
            <h1 className="reveal-from-bottom" data-reveal-delay="200">
              <span className="text-color-primary">Algor</span>Blog
            </h1>
            <div className="split-item reveal-from-left" data-reveal-delay="400">
              <div className="split-item-image center-content-mobile split-item-image-fill animate-zoom-in" data-reveal-container=".split-item">
                  <Image
                    src='https://i.ibb.co/wrBtCw6/christina-wocintechchat-com-7-PHq2-BCa7d-M-unsplash.jpg'
                    alt="blog-featured"
                    width={300}
                    height={200} />
              </div>
              <div className="split-item-content" data-reveal-container=".split-item">
                <div className="blog-tags ta-l text-color-high mt-16">
                  <span className="blog-tag-subject blog-tag-subject--color-one text-xxs">Automação</span>
                  <span className="blog-tag-subject blog-tag-subject--color-five text-xxs">Suporte</span>
                </div>
                <a className='blog-article-title' href="#0">
                  <h3 className='ta-l mt-8 mb-0'>Aumente sua produtividade automatizando tarefas rotineiras com bots e scripts</h3>
                </a>
                <p className='ta-l mt-8 text-sm'>Como chatbots podem melhorar a experiência de clientes e facilitar o processo interação e suporte.</p>
                <p className='ta-l text-xxs'>Por Deoclides Neto · 1, Março 2017</p>
              </div>
            </div>
          </div>

          <div className="split-wrap blog-featured-content">
            <h1 className="reveal-from-bottom" data-reveal-delay="200">
              
            </h1>
            <div className="split-item reveal-from-left" data-reveal-delay="400">
              <div className="split-item-image center-content-mobile split-item-image-fill animate-zoom-in" data-reveal-container=".split-item">
                  <Image
                    src='https://miro.medium.com/max/700/0*VIWtMtQMwg4uw44g'
                    alt="blog-featured"
                    width={300}
                    height={200} />
              </div>
              <div className="split-item-content" data-reveal-container=".split-item">
                <div className="blog-tags ta-l text-color-high mt-16">
                  <span className="blog-tag-subject blog-tag-subject--color-four text-xxs">Soft Skill</span>
                </div>
                <a className='blog-article-title' href="#0">
                  <h3 className='ta-l mt-8 mb-0'>Você nunca é bom demais para o básico</h3>
                </a>
                <p className='ta-l mt-8 text-sm'>Todo mundo quer conhecimento avançado. Mas normalmente é o básico que mais importa.</p>
                <p className='ta-l text-xxs'>Por Leonardo Lins · 3, Agosto 2022</p>
              </div>
            </div>
          </div>

          <div className="split-wrap blog-featured-content">
            
            <h1 className="reveal-from-bottom" data-reveal-delay="200">
              
            </h1>
            <div className="split-item reveal-from-left" data-reveal-delay="400">
              <div className="split-item-image center-content-mobile split-item-image-fill animate-zoom-in" data-reveal-container=".split-item">
                  <Image
                    src='https://images.unsplash.com/photo-1531747118685-ca8fa6e08806?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1045&q=80'
                    alt="blog-featured"
                    width={300}
                    height={200} />
              </div>
              <div className="split-item-content" data-reveal-container=".split-item">
                <div className="blog-tags ta-l text-color-high mt-16">
                  <span className="blog-tag-subject blog-tag-subject--color-five text-xxs">Inteligência Artificial</span>
                  <span className="blog-tag-subject blog-tag-subject--color-four text-xxs">Visão Computacional</span>
                </div>
                <a className='blog-article-title' href="https://medium.com/@dmitryelj/how-the-convolutional-neural-network-see-the-world-a36cd102e7c8">
                  <h3 className='ta-l mt-8 mb-0'>Como a Rede Neural Convolucional vê o mundo?</h3>
                </a>
                <p className='ta-l mt-8 text-sm'>Muitas redes neurais de última geração estão facilmente disponíveis para desenvolvedores hoje, mas como isso realmente funciona?</p>
                <p className='ta-l text-xxs'>Por Dmitri Eliuseev · 5, Agosto 2022</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

BlogFeatured.propTypes = propTypes;
BlogFeatured.defaultProps = defaultProps;

export default BlogFeatured;